﻿/// <reference path="app.less" />

body {
    background: @page-bg;
    color: @base-text-colour;
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
}

.main-container {
    font-size: 0.9375rem; //15px;
}

h1, h2, h3 {
    font-size: 2.25rem;
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
    color: @headings-text;
    font-weight: 300;
}

a {
    color: inherit;
    text-decoration: none;
}

.panel {
    color: @panel-text;
    text-align: left;
    font-family: 'Roboto Condensed',sans-serif;
    box-sizing: border-box;
    max-width: 352px;
    margin: 0 auto;

    iframe {
        display: none;
    }
}

.panel-body {
    color: @panel-body-text;
    box-sizing: border-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.notice .panel {
    text-align: center;
}

.offline {
    margin-top: 180px;
}

.login-wrapper {
    margin-top: 64px;
}

@media all and (min-height: 640px) {

    .login .login-wrapper {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(calc(-50% + 32px));
        margin: 0;
    }
}

@media all and (min-width: 720px) and (min-height: 960px) {
    .sign-up .login-wrapper {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(calc(-50% + 44px));
        margin: 0;
    }
}

.hide-mobile {
    display: none;
}

.show-mobile {
    display: block;
}

.page-body {
    padding: 32px;
}

p a {
    color: #fff;
}

@media all and (min-width: 720px) {

    .panel-body {
        background: @panel-body-bg;
        padding: 3rem;
    }

    .hide-mobile {
        display: block;
    }

    .show-mobile {
        display: none;
    }

    .page-body {
        padding: 0;
    }
}


@media all and (hover: hover) {
    p a:hover {
        text-decoration: underline;
    }
}
