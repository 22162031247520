﻿/// <reference path="app.less" />

.danger {
    color: @danger-text;
}

.validation-summary-errors {
    font-weight: 300;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

.input-validation-error {
    border: 2px solid #eb005f;
}
