﻿/// <reference path="app.less" />

.form-group {
    margin-bottom: 1.5rem; 

    &:last-of-type {
        margin: 0;
    }

    .icon {
        width: 18px;
        height: 18px;
    }
}

.form-control {
    border: 0;
    margin: 0;
    padding: 0 16px;
    flex: auto;
    width: 100%;
    background: none;

    &:focus {
        outline: 0;
    }
}

.input-field {
    display: flex;
    background: @input-bg;
    color: @input-text;
    // -webkit-border-radius: 3px;
    // -moz-border-radius: 3px;
    // border-radius: 3px;
    font-family: 'Roboto Condensed',sans-serif;
    font-size: 0.9375rem; //15px;
    font-weight: 300;
    height: 2.5rem; //40px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .icon {
        height: 100%;
        min-width: 40px;
        background: none;
        border: 0;
        padding: 0;

        svg {
            vertical-align: middle;
            width: 18px;
        }
    }
}

#toggle-password #hidden { 
    display: none; 
}

#toggle-password.show {
    #hidden { display: block; }
    #visible { display: none; }
}


label {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: @headings-text;
    margin-bottom: 1.5rem;
    display: block;
    font-weight: 400;
    font-size: .875rem;
}

.login-remember label {
    font-family: 'Roboto Condensed',sans-serif;
}
