﻿/// <reference path="app.less" />

.btn {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 0;
    font-weight: 300;
    text-align: center;
    color: @btn-default-text;
    background: @btn-default-bg;
    border: 1px solid @btn-default-bg;
    white-space: nowrap;
    padding: 0 16px;
    min-height: 40px;
    font-size: 0.9375rem;
    line-height: 1.35;
    // -webkit-border-radius: 3px;
    // -moz-border-radius: 3px;
    // border-radius: 3px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'Roboto Condensed', sans-serif;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;

    span {
        flex: auto;
    }

    .icon {
        width: 18px;
        height: 18px;
    }
}

.submit-button {
    position: relative;
    top: auto;
    bottom: auto;
    padding: 11px 38px;
    border-style: solid;
    border-width: 2px;
    border-color: #5fa0ff;
    background-color: #5fa0ff;
    -webkit-transition: background-color .2s ease;
    transition: background-color .2s ease;
    font-family: 'Roboto Condensed',sans-serif;
    color: #fff;
    font-size: 1em;
    line-height: 1.625em;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
}

.btn:disabled {
    opacity: 0.25;
    cursor: not-allowed;
}

.btn-primary {
    color: @btn-primary-text;
    background-color: @btn-primary-bg;
    border-color: @btn-primary-bg;

    path {
        fill: @btn-primary-text;
    }
}

a.btn {
    text-decoration: none;
}

@media all and (hover: hover) {
    .btn-default:not(:disabled) {

        &:hover {
            background: transparent;
            color: @btn-default-bg;

            path {
                fill: @btn-default-bg;
            }
        }
    }

    .btn-primary:not(:disabled) {
        &:hover {
            background: transparent;
            color: @btn-primary-bg;

            path {
                fill: @btn-primary-bg;
            }
        }
    }

    .submit-button:hover {
        border: 2px solid #fff;
        background-color: transparent;
        color: #fff;
    }
}