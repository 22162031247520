.split-container {
  //width: calc(100% - 66px);
  max-width: 790px;
  margin: 0px auto;
}

.split-container-section {
  flex-basis: 100%;

  .panel,
  .panel-body {
    height: 100%;
  }
}

.split-container-divider {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 32px 0;

  span {
    margin: 0 auto;
  }
}

.external-providers .btn {
  margin-bottom: 1.5rem;
}

.external-providers .btn:last-of-type {
  margin: 0;
}



@media all and (min-width: 720px) {
  .split-container {
    display: flex;
    flex-direction: row;
    max-width: 775px;
    align-items: stretch;
  }

  .split-container-divider {
    flex-direction: column;
    align-self: center;
    padding: 0px 33px;
  }

  .sign-up {
    .split-container {
      flex-direction: column;
    }

    .split-container-divider {
      padding: 32px 0;
    }
  }
}

@media all and (hover: hover) {
  .external-providers .btn:hover {
    background: rgb(235, 235, 235);
    color: initial;
  }
}