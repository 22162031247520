﻿.loader-wrap {
    overflow: hidden;
}

.loader {
    margin: 3.75rem auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: .3125rem solid rgba(255, 255, 255, 0.2);
    border-right: .3125rem solid rgba(255, 255, 255, 0.2);
    border-bottom: .3125rem solid rgba(255, 255, 255, 0.2);
    border-left: .3125rem solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load 1.1s infinite linear;
    animation: load 1.1s infinite linear;
    max-height: 2.75rem;
    max-width: 2.75rem;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

@-webkit-keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
