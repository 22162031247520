﻿/// <reference path="app.less" />

.main-header {
    background: @mobile-header-bg;
}

.logo,
.mobile-logo {
    display: flex;
    position: relative;
    z-index: 500;

    path {
        fill: @logo-color;
    }
}

.mobile-logo {
    width: 2.6875rem; //43px
    height: 2.6875rem; //43px
    box-sizing: border-box;
    padding: .5rem; //8px
}

.logo {
    height: 2.6875rem; //43px
    width: 11.375rem; //182px
}

.capped {
    max-width: 100rem;
    margin: 0 auto;
}

.nav-grid {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
    width: 100%;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1em;
    line-height: 2em;
    font-weight: 400;
    letter-spacing: 0px;
    color: #fff;

    .hide-mobile,
    .show-mobile {
        flex: auto;
    }

    li:last-child {
        margin-left: 32px;
    }
}

.sign-up {
    .nav-grid .signup-link {
        display: none;
    }
}

.login-link {
    margin-right: 32px;
}

.login {
    .nav-grid .login-link {
        display: none;
    }
}


@media all and (min-width: 720px) {

    .main-header {
        background: transparent;
        padding-top: 5rem;
        padding-bottom: 1.875rem;
    }

    .capped {
        padding-left: 5%;
        padding-right: 5%;
    }
}
